import Axios from 'axios/index';
import Qs from 'qs';
import Cookies from 'vue-cookies';
import alert from './alert.js';

const errorMixin = {
  showErrorExitCode1(response) {
    if (response.data.exitCode === 1) {
      alert.showErrorMessage('Ошибка выполнения');
    }
    return response;
  },

  showError(error) {
    if(error.response) {
      if (error.response.status === 403) {
        window.location.href = './login';
      } else if (error.response.status === 413) {
        alert.showErrorMessage(`Большой объем данных`);
      } else if (error.response.data.exitCode === undefined || error.response.data.exitCode === 1) {
        alert.showErrorMessage(`Не удалось загрузить данные.<br>Ошибка ${error.response.status} (${error.response.data.msg !== undefined ? error.response.data.msg : 'Неизвестная'})`)
      } else if (error.response.status && error.response.status >= 500) {
        alert.showErrorMessage('Ошибка загрузки данных');
        throw error;
      }
    }
    return error.response;
  },
};

const errorUtils = Object.create(errorMixin);

export const get = (url, params) => {
  const token = Cookies.get('token');
  if (token) {
    params.token = token;
  }
  return Axios.get((url.startsWith('/lumen') ? '' : '/backend/admin/vue_backend/') + url, {
    params,
    paramsSerializer: (params) => Qs.stringify(params),
  }).then((response) => errorUtils.showErrorExitCode1.bind(this)(response))
    .catch((error) => errorUtils.showError.bind(this)(error));
}

export const post = (url, params) => {
  const token = Cookies.get('token');
  if (token) {
    params.token = token;
  }
  return Axios.post((url.startsWith('/lumen') ? '' : '/backend/admin/vue_backend/') + url,
    Qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response) => errorUtils.showErrorExitCode1.bind(this)(response))
    .catch((error) => errorUtils.showError.bind(this)(error));
}

export const postFile = (url, params) => {
  const token = Cookies.get('token');
  if (token) {
    params.append('token', token);
  }
  return Axios.post((url.startsWith('/lumen') ? '' : '/backend/admin/vue_backend/') + url,
    params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => errorUtils.showErrorExitCode1.bind(this)(response))
    .catch((error) => errorUtils.showError.bind(this)(error));
}

export const restFullDelete = (url, params) => {
  const token = Cookies.get('token');
  if (token) {
    params.token = token;
  }
  return Axios.delete((url.startsWith('/lumen') ? '' : '/backend/admin/vue_backend/') + url, { data: params })
    .then((response) => errorUtils.showErrorExitCode1.bind(this)(response))
    .catch((error) => errorUtils.showError.bind(this)(error));
}
