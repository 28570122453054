<template>
  <div :class="toplabel==''?'main-app-datepicker-inline':'main-app-datepicker'">
    <div v-if="toplabel!=''" class="toplabel">{{ toplabel }}</div>
    <app-datepicker v-model="valueLocal" :disabled="disabled" />
  </div>
</template>

<script>
export default {
  name: 'AppDatepickerToplabel',
  param: '',
  props: {
    toplabel: {
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: String,
  },

  data() {
    return {
      valueLocal: this.modelValue,
    };
  },

  watch: {
    modelValue(newVal) {
      this.valueLocal = newVal;
    },
    valueLocal(newVal) {
      this.$emit('update:modelValue', newVal);
      this.$emit('changeAction', newVal);
    },
  },
};
</script>

<style scoped>
.date {
  font-size: 14px;
  height: 30px;
  width: 100%;
}

.date > > > .btn {
  padding: 0px 13px;
  margin: -1px 0px;
}

.date > > > label {
  padding-top: 3px;
}

.hide {
  display: none;
}

.toplabel {
  background-color: white;
  width: auto !important;
  display: inline;
  position: absolute;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95A0AA;
  z-index: 1;
  margin-left: 5px;
  margin-top: -7px;
  padding: 0px 3px;
  height: 10px;
  left: 0px;
}

.main-app-datepicker {
  padding-top: 7px;
  display: inline-block;
  position: relative;
}

.main-app-datepicker-inline {
  display: inline-block;
}

</style>
